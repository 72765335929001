import { injectIntl } from 'gatsby-plugin-intl'
import React from 'react'
import Layout from '../components/layout'
import Placeholder from '../components/placeholder'
import Seo from '../components/seo'

const Clergy = ({ intl }) => (
  <Layout>
    <Seo title={intl.formatMessage({ id: 'our_clergy' })} />
    <Placeholder />
  </Layout>
)

export default injectIntl(Clergy)
