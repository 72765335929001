import {graphql, useStaticQuery} from 'gatsby'
import {GatsbyImage} from "gatsby-plugin-image";
import {useIntl} from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'
import {StyledLinkWithHover} from '../styles/common'

const Container = styled.div`
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`

const Placeholder = () => {
    const intl = useIntl()
    const {contentfulAlbum} = useStaticQuery(
        graphql`
          query {
            contentfulAlbum(name: { eq: "stock" }) {
              frontpage {
                fluid: gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        `,
    )

    return (
        <Container>
            <GatsbyImage
                image={contentfulAlbum['frontpage'].fluid}
                style={{
                    width: `80vw`,
                    margin: '0 auto',
                }} alt={'Placeholder'}/>
            <StyledLinkWithHover to='/'>
                {intl.formatMessage({id: 'home_page'})}
            </StyledLinkWithHover>
        </Container>
    );
}

export default Placeholder
